import React, { useEffect, useState } from 'react'
import useStateWithLocalStorage from '../hooks/use_state_with_local_storage'
import { storefrontFetch } from '../misc/fetch_helpers'

import { Alert } from 'lensrentals-components'

import styles from '../../assets/stylesheets/_site_alerts.module.scss'

function createAlertId(alert) {
  const attributes = [alert.color, alert.link?.text, alert.link?.url, alert.text].filter(Boolean).join(' ')
  // return an id created from everything unique about this alert
  const encoded = typeof window !== 'undefined' ? btoa(attributes) : Buffer.from(attributes).toString('base64')
  return encoded
}

function toCSSVariable(color) {
  // make this conform to --colorColor
  return `var(--color${color.charAt(0).toUpperCase() + color.slice(1)})`
}

export default function SiteAlerts() {
  const [alerts, setAlerts] = useState(null)
  const [error, setError] = useState()

  useEffect(() => {
    const getAlerts = async () => {
      try {
        const response = await storefrontFetch('/site_alerts_list.json')
        const alerts = response.map(alert => alert.data)
        setAlerts(alerts)
      } catch (error) {
        setError(true)
        console.error('error fetching site alerts: ', error)
      }
    }

    if (!alerts) {
      getAlerts()
    }
  }, [alerts])

  const activeAlerts = (alerts || [])
    .filter(alert => alert.active)
    .map((alert) => {
      const id = createAlertId(alert)
      const handleClose = () => {
        setDismissedAlerts(dismissedAlerts => [
          ...dismissedAlerts,
          { id, handleClose, timestamp: Date.now() }
        ])
      }

      return { ...alert, id, handleClose }
    })

  const [dismissedAlerts, setDismissedAlerts] = useStateWithLocalStorage('dismissedAlerts', [])

  // if an alert was dismissed long enough ago and for some reason still exists
  // we should show it to the user again, its probably important
  const expiration = 30 * 24 * 60 * 60 * 1000 // 30 days
  useEffect(() => {
    const nonExpiredDismissals = dismissedAlerts.filter(alert => {
      return (Date.now() - alert.timestamp) < expiration
    })
    setDismissedAlerts(nonExpiredDismissals)
  }, [expiration, dismissedAlerts, setDismissedAlerts])

  // this needs to initially render a blank array on the server to avoid a hydration mismatch
  const [renderedAlerts, setRenderedAlerts] = useState([])

  useEffect(() => {
    setRenderedAlerts(currentRenderedAlerts => {
      const newRenderedAlerts = activeAlerts.filter(alert => {
        return !dismissedAlerts.map(alert => alert.id).includes(alert.id)
      })

      const newRenderedAlertsIds = newRenderedAlerts.map(alert => alert.id)
      const currentRenderedAlertsIds = currentRenderedAlerts.map(alert => alert.id)
      const hasChanged = !(currentRenderedAlertsIds.every(id => newRenderedAlertsIds.includes(id)) && newRenderedAlertsIds.every(id => currentRenderedAlertsIds.includes(id)))

      if (hasChanged) {
        return newRenderedAlerts
      } else {
        return currentRenderedAlerts
      }
    })
  }, [activeAlerts, dismissedAlerts])

  if (!alerts || error) return null

  return (
    <section className={styles['site-alerts']}>
      {renderedAlerts.map((alert) => {
        return (
            <div
              key={alert.id}
              style={{ '--color': toCSSVariable(alert.color) }}
            >
              <Alert
                show={true}
                onClose={alert.handleClose}
                color={alert.color}
              >
                <p>
                  {alert.text}
                  {alert.link.url && alert.link.text && (<a href={alert.link.url}>{alert.link.text}</a>)}
                  </p>
              </Alert>
            </div>
        )
      })}
    </section>
  )
}
