import { useState, useEffect, useCallback } from 'react'

function useRawLocalStorageKey(key) {
  const [state, setState] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(key)
    }
  })

  const setter = useCallback((valueOrCallback) => {
    setState(state => {
      const value = typeof valueOrCallback === 'function' ? valueOrCallback(state) : valueOrCallback

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, value)
      }

      return value
    })
  }, [key, setState])

  const handleStorageEvent = useCallback((event) => {
    if (event.key === key) {
      setState(event.newValue)
    }
  }, [key])

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent)
    setState(window.localStorage.getItem(key))
    return () => {
      window.removeEventListener('storage', handleStorageEvent)
    }
  }, [handleStorageEvent, key])

  return [state, setter]
}

function useLocalStorageKey(key, defaultValueOrCallback = null) {
  const [raw, setRaw] = useRawLocalStorageKey(key)

  const setter = useCallback((valueOrCallback) => {
    setRaw((currentState) => {
      const value = typeof valueOrCallback === 'function' ? valueOrCallback(JSON.parse(currentState)) : valueOrCallback
      return JSON.stringify(value)
    })
  }, [setRaw])

  if (raw) {
    try {
      const parsed = JSON.parse(raw)
      return [parsed, setter]
    } catch (e) {
      // fall through
    }
  }

  let defaultValue
  if (typeof defaultValueOrCallback === 'function') {
    defaultValue = defaultValueOrCallback()
  } else {
    defaultValue = defaultValueOrCallback
  }
  setter(defaultValue)
  return [defaultValue, setter]
}

export { useRawLocalStorageKey, useLocalStorageKey }
export default useLocalStorageKey
