export const storefrontFetch = (resourcePath, params, urlParams = {}) => {
  if (!params) {
    params = {}
  }

  if (params && params.body) {
    params.body = JSON.stringify(params.body)
  }

  params.headers = {
    'Content-Type': 'application/json', // for cas-middleware
    'X-Requested-With': 'XMLHttpRequest'
  }

  if (window.storefront_session && window.storefront_session.csrf) {
    params.headers['X-Csrf-Token'] = window.storefront_session.csrf
  } else if (typeof $ !== 'undefined') {
    params.headers['X-Csrf-Token'] = $('meta[name="csrf-token"]').attr(
      'content'
    )
  }

  params.credentials = 'include'

  if (Object.keys(urlParams).length) {
    const query = new URLSearchParams()

    for (const param in urlParams) {
      query.set(param, urlParams[param])
    }

    resourcePath = `${resourcePath}?${query.toString()}`
  }

  return fetch(resourcePath, params).then(response => {
    return response.json().then(json => {
      return response.ok ? json : Promise.reject(json)
    })
  })
}

export async function fetchExtension(orderNumber, extendToDate) {
  const url = new URLSearchParams()
  url.set('extension_date', extendToDate.format('YYYY-MM-DD'))
  const queryString = url.toString().length > 0 ? `?${url.toString()}` : ''
  const response = await storefrontFetch(`/orders/${orderNumber}/extension.json` + queryString)
  const extension = response.data.orders?.edges[0]?.node?.proposedExtension
  return { data: extension, errors: response.errors }
}

export async function fetchOrderInfo(orderNumber) {
  const orderInfo = await storefrontFetch(`/orders/${orderNumber}.json`)
  const orderData = orderInfo.data.orders?.edges[0]?.node
  return { data: orderData, errors: orderInfo.errors }
}

export async function applyExtension(orderId, proposedExtensionId) {
  const input = {
    order_id: orderId,
    proposed_extension_id: proposedExtensionId
  }
  return await storefrontFetch('/order_extensions', { method: 'post', body: input })
}

export async function replaceStripeCard({ orderId, stripeTokenId, billingName, orderNumber }) {
  const input = {
    // Have to use a different param name because `order_id` is reserved by the Rails Route
    relay_order_id: orderId,
    stripe_token_id: stripeTokenId,
    billing_name: billingName
  }
  return await storefrontFetch(`/orders/${orderNumber}/card`, { method: 'put', body: input })
}
